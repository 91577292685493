<script lang="ts">
  import Bounded from "../ui/Bounded.svelte"
  import TopHeader from "../ui/TopHeader.svelte"
  import ProductCard from "../ui/ProductCard/ProductCard.svelte"
  import Button from "../ui/Button.svelte"
  import ProductCardName from "../ui/ProductCard/ProductCardInfo/ProductCardName.svelte"
  import ProductCardTag from "../ui/ProductCard/ProductCardInfo/ProductCardTag.svelte"
  import ProductCardImage from "../ui/ProductCard/ProductCardImage.svelte"
  import ProductCardFields from "../ui/ProductCard/ProductCardFields.svelte"
  import ProductCardFeatures from "../ui/ProductCard/ProductCardFeatures.svelte"
  import ViewProductButton from "../ui/ProductCard/ViewProductButton.svelte"
  import AdvicePage from "../ui/AdvicePage.svelte"
  import BottomNavigation from "../ui/BottomNavigation.svelte"
  import ProductCardPrice from "../ui/ProductCard/ProductCardInfo/ProductCardPrice.svelte"
  import TopNavigation from "../ui/TopNavigation.svelte"
  import previewImg from "./preview-product-img.jpg"
  import ProductCardReview from "../ui/ProductCard/ProductCardReview.svelte"
  import Recommendation from "../app/Recommendation.svelte"
  import { type Recommendation as RecommendationT } from "../app/toPage"
  export let cardCount = 3

  const recommendation: RecommendationT = {
    productName: "Product name",
    imageUrl: previewImg,
    productUrl: "https://",
    price: "€ 12,-",
    salePrice: {
      previousPriceAnnotation: "",
      salePeriodEnd: undefined,
      salePeriodStart: undefined,
      price: "€ 12,-",
    },
    state: undefined,
    recommendationContext: {
      recommendationPosition: 0,
      productContext: "RECOMMENDATION",
    },
    productId: "product-id",
    clientProductId: "",
    isProductCheckProduct: false,
    productData: {},
    specification: {
      price: null,
    },
    traceability: [
      {
        score: "bad",
        answer: "Some answer",
      },
      {
        score: "good",
        answer: "Some answer",
      },
      {
        score: "perfect",
        answer: "Some answer",
      },
    ],
    buttonCtaText: "",
    energyLabelField: undefined,
    additionalFields: [
      {
        label: "Label",
        suffix: "",
        values: ["Magna Lorem Vestibulum Ligula"],
      },
    ],
    pricePrefix: "",
    priceSuffix: "",
    variants: [],
    reviewScore: 3.5,
    reviewCount: 12345,
    display: {
      bestMatch: true,
      traceability: true,
      price: true,
      productCta: true,
      ctaType: "ProductLink",
    },
  }
</script>

<div class="wrapper">
  <AdvicePage>
    <span slot="top-header">
      <Bounded>
        <TopHeader
          title="Advice Page title"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, [consectetur]() adipiscing elit."
          smallText="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        />
        <Button>Newsletter</Button>
      </Bounded>
    </span>
    <span slot="bottom-section">
      <Bounded>
        {#each Array(cardCount) as _, i}
          <Recommendation
            ariaLabel={i == 0
              ? `Best match label: ${recommendation.productName}`
              : `Neutral label: ${recommendation.productName}`}
            {recommendation}
          >
            <!-- <span slot="product-card-tags"> -->
            {#if i == 0}
              <ProductCardTag
                topLabel={{ kind: "good", label: "Best match label" }}
              />
            {:else if i == 1}
              <ProductCardTag
                topLabel={{ kind: "neutral", label: "Neutral label" }}
              />
            {/if}
            <!-- </span> -->
          </Recommendation>
        {/each}
      </Bounded>
      <BottomNavigation hideNext disableSticky />
    </span>
  </AdvicePage>
</div>

<style>
  .wrapper {
    --aiden-navigation-button-color: var(
      --aiden-advice-page-top-navigation-button-color
    );
    --aiden-navigation-button-background-color-hover: var(
      --aiden-advice-page-top-navigation-button-background-color-hover
    );
    --aiden-navigation-button-color-hover: var(
      --aiden-advice-page-top-navigation-button-color-hover
    );
  }
</style>
