import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Mostre mais",
  showLess: "Mostre menos",
  bestMatch: "Melhor partida",
  view: "Dê uma vista de olhos",
  customAction: "",
  notFound: "404: não encontrado",
  reset: "Recomeçar",
  close: "Fechar",
  noAdviceTitle:
    "Atualmente não há produtos disponíveis que correspondam totalmente às suas preferências.",
  noAdviceMessage: "Ajuste as suas preferências ou tente novamente mais tarde.",
  prev: "Anterior",
  next: "Próximo",
  backToAdvice: "Voltar ao conselho",
  dontKnow: "Pular",
  email: "Endereço de email",
  send: "Enviar",
  emailError: "Digite um endereço de e-mail válido",
  readMore: "Mais informação",
  selectAnAnswer: "Selecione uma resposta",
  answerNotListed: "Minha resposta não está aí",
  whyThisProductForMe: "Por que isso combina comigo?",
  alternative: "alternativa",
  alternatives: "alternativas",
  seeAlso: "Olhe também",
  badMatchIcon: "Má correspondência",
  goodMatchIcon: "Boa correspondência",
  perfectMatchIcon: "Correspondência perfeita",
  singleAnswerQuestionLegend: "Escolha uma opção e continue.",
  multiAnswerQuestionLegend: "Escolha uma ou mais opções.",
  multiAnswerQuestionWithNeutralLegend: "Escolha uma ou mais opções, ou a opção inferior se não tiver preferência.",
  manualDrilldownLegend: "Selecione uma opção. Se existir uma pergunta complementar, esta aparecerá após a escolha de uma resposta. Utilize o botão 'Próximo' para continuar depois de responder a todas as perguntas.",
  autoDrilldownLegend: "Selecione uma opção. Se existir uma pergunta complementar, esta aparecerá após a escolha de uma resposta. Utilize o botão 'Próximo' para continuar depois de responder a todas as perguntas. A sua resposta não está listada? De seguida, selecione 'Minha resposta não está aí'.",
  loading: "Carregando",
  progress: "Progresso",
  numberInputDefaultLabel: "Introduza um número.",
  numberInputWithUnitLabel: "Introduza um número em {{unit}}.",
  actionSucceededLabel: "Sucesso",
  actionFailedLabel: "Falhou",
  reviewScoreLabel: "Este produto foi classificado com: {{score}} de 5 estrelas com base nos comentários de {{count}}.",
}

export default translations
