<script lang="ts">
  import ArrowLeft from "../icons/ArrowLeft.svelte"
  import Cross from "../icons/Cross.svelte"
  import Loader from "../icons/Loader.svelte"
  import ResetNew from "../icons/ResetNew.svelte"
  import { t } from "../lang/i18n"
  import { navigationContext } from "../stores/navigation"
  import Button from "./Button.svelte"
  import ProgressBar from "./ProgressBar.svelte"

  export let progress: number = 0
  export let hideProgress = false
  export let hideStartOver = false
  export let hideClose = false
  export let onClose: () => void = () => {}
  export let onStartOver: () => void = () => {}
  export let showBackButtonForPreview: boolean = false
</script>

<!-- <Bounded> -->
<div
  class="top-navigation max-app-width"
  class:is-hidden={hideStartOver && hideClose && hideProgress}
>
  <div class="buttons">
    {#if $navigationContext.hasPreviousScreen || showBackButtonForPreview}
      <Button variant="navigation" onClick={$navigationContext.prev}>
        {#if $navigationContext.loadingPreviousScreen}
          <Loader aria-label={$t("loading")} />
        {:else}
          <ArrowLeft aria-labelledby="return-button-label" />
        {/if}
        <span id="return-button-label" class="navigation-label"> {$t("prev")}</span>
      </Button>
    {:else}
      <div class="button-placeholder" />
    {/if}

    {#if !hideStartOver}
      <Button variant="navigation" onClick={onStartOver}>
        <span id="reset-button-label" class="navigation-label"> {$t("reset")}</span>
        {#if $navigationContext.startingOver}
          <Loader aria-label={$t("loading")} />
        {:else}
          <ResetNew aria-labelledby="reset-button-label" />
        {/if}
      </Button>
    {:else}
      <div class="button-placeholder" />
    {/if}

    {#if !hideClose}
      <Button variant="navigation" onClick={onClose}>
        <span id="close-button-label" class="navigation-label"> {$t("close")}</span>
        <Cross aria-labelledby="close-button-label" />
      </Button>
    {:else}
      <div class="button-placeholder" />
    {/if}
  </div>
  <div class="progress-bar" class:is-hidden={hideProgress}>
    <ProgressBar ariaLabel={$t("progress")} {progress} />
  </div>
</div>

<!-- </Bounded> -->

<style>
  .top-navigation {
    padding: 0.25rem 0 0 0;
    padding-left: var(--frame-padding, 1rem);
    padding-right: var(--frame-padding, 1rem);
    padding-top: var(--frame-padding, 1rem);
    background-color: var(--aiden-top-navigation-background-color);
    /* min-height: 3.5rem; */
    width: 100%;
    box-sizing: border-box;
  }
  .navigation-label {
    display: none;
  }
  .button-placeholder {
    width: 1.5rem;
  }
  @media screen and (min-width: 28rem) {
    .navigation-label {
      display: inline;
    }
    .button-placeholder {
      width: 5.625rem;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    align-items: center;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    gap: 0.5rem 0;
  }
  .progress-bar {
    /* transition: 150ms all ease-out; */
    margin-top: 0.25rem;
    padding: 0;
    transition-behavior: allow-discrete;
  }
  .is-hidden {
    display: none;
    opacity: 0;
  }
</style>
