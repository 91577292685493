import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Покажи повече",
  showLess: "Покажи по-малко",
  bestMatch: "Най-добър мач",
  view: "Да гледам",
  customAction: "В пазарската количка",
  notFound: "404: Не е намерено",
  reset: "Започнете отначало",
  close: "Слуитен",
  noAdviceTitle:
    "В момента няма налични продукти, които да отговарят напълно на вашите предпочитания.",
  noAdviceMessage: "Можете да промените предпочитанията си или да опитате отново по-късно.",
  prev: "Предишен",
  next: "Следваща",
  backToAdvice: "Обратно към съвета",
  dontKnow: "Пропускане",
  email: "Имейл адрес",
  send: "Изпратете",
  emailError: "Моля, въведете валиден имейл адрес",
  readMore: "Прочетете повече",
  selectAnAnswer: "Изберете отговор",
  answerNotListed: "Отговорът ми не е в списъка",
  whyThisProductForMe: "Защо този съвет?",
  alternative: "алтернатива",
  alternatives: "алтернативи",
  seeAlso: "Вижте също",
  badMatchIcon: "Лошо съвпадение",
  goodMatchIcon: "Добро съвпадение",
  perfectMatchIcon: "Перфектно съвпадение",
  singleAnswerQuestionLegend: "Изберете една опция и продължете.",
  multiAnswerQuestionLegend: "Изберете една или повече опции.",
  multiAnswerQuestionWithNeutralLegend: "Изберете една или повече опции или най-долната опция, ако нямате предпочитания.",
  manualDrilldownLegend: "Изберете една опция. Ако има последващ въпрос, той ще се появи след избор на отговор. Използвайте бутона 'Следваща', за да продължите, след като отговорите на всички въпроси.",
  autoDrilldownLegend: "Bir seçeneği seçin. Bir takip sorusu varsa, bir cevap seçildikten sonra görünecektir. Tüm soruları cevapladıktan sonra devam etmek için 'Следваща' butonunu kullanın. Cevabınız listelenmemiş mi? Daha sonra 'Отговорът ми не е в списъка'u seçin.",
  loading: "Зареждане",
  progress: "Напредък",
  numberInputDefaultLabel: "Въведете номер.",
  numberInputWithUnitLabel: "Въведете число в {{unit}}.",
  actionSucceededLabel: "Успял",
  actionFailedLabel: "Неуспешно",
  reviewScoreLabel: "Този продукт е оценен: {{score}} от 5 звезди въз основа на {{count}} отзива.",
}

export default translations
