import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Δείτε περισσότερα",
  showLess: "Δείτε λιγότερα",
  bestMatch: "Συνιστάται από εμάς",
  view: "Κυτάζω",
  customAction: "",
  notFound: "404: Δεν βρέθηκε",
  reset: "Ξεκινήστε από την αρχή",
  close: "Τέλος",
  noAdviceTitle: "Αυτή τη στιγμή δεν υπάρχουν διαθέσιμα προϊόντα που να ταιριάζουν πλήρως με τις προτιμήσεις σας.",
  noAdviceMessage: "Μπορείτε να αλλάξετε τις προτιμήσεις σας ή να δοκιμάσετε αργότερα.",
  prev: "Προηγούμενο",
  next: "Επόμενο",
  backToAdvice: "Επιστροφή στην συμβουλή",
  dontKnow: "Παραλείπω",
  email: "Διεύθυνση email",
  send: "Υποτάσσομαι",
  emailError: "Παρακαλώ εισάγετε μια έγκυρη διεύθυνση email",
  readMore: "Διαβάστε περισσότερα",
  selectAnAnswer: "Επιλέξτε μια απάντηση",
  answerNotListed: "Η απάντησή μου δεν αναφέρεται",
  whyThisProductForMe: "Γιατί αυτή η συμβουλή",
  alternative: "εναλλακτική",
  alternatives: "εναλλακτικές",
  seeAlso: "Δείτε επίσης",
  badMatchIcon: "Κακή αντιστοιχία",
  goodMatchIcon: "Καλή αντιστοιχία",
  perfectMatchIcon: "Τέλεια αντιστοιχία",
  singleAnswerQuestionLegend: "Επιλέξτε μία επιλογή και συνεχίστε.",
  multiAnswerQuestionLegend: "Επιλέξτε μία ή περισσότερες επιλογές.",
  multiAnswerQuestionWithNeutralLegend: "Επιλέξτε μία ή περισσότερες επιλογές ή την κάτω επιλογή εάν δεν έχετε προτίμηση.",
  manualDrilldownLegend: "Επιλέξτε μία επιλογή. Εάν υπάρχει μια επόμενη ερώτηση, θα εμφανιστεί αφού επιλέξετε μια απάντηση. Χρησιμοποιήστε το κουμπί «Επόμενο» για να συνεχίσετε αφού απαντήσετε σε όλες τις ερωτήσεις.",
  autoDrilldownLegend: "Επιλέξτε μία επιλογή. Εάν υπάρχει μια επόμενη ερώτηση, θα εμφανιστεί αφού επιλέξετε μια απάντηση. Χρησιμοποιήστε το κουμπί «Επόμενο» για να συνεχίσετε αφού απαντήσετε σε όλες τις ερωτήσεις. Δεν αναφέρεται η απάντησή σας; Στη συνέχεια, επιλέξτε «Η απάντησή μου δεν αναφέρεται».",
  loading: "Φόρτωση",
  progress: "Πρόοδος",
  numberInputDefaultLabel: "Εισαγάγετε έναν αριθμό.",
  numberInputWithUnitLabel: "Εισαγάγετε έναν αριθμό στο {{unit}}.",
  actionSucceededLabel: "Πέτυχε",
  actionFailedLabel: "Αποτυχημένος",
  reviewScoreLabel: "Αυτό το προϊόν έχει βαθμολογηθεί: {{score}} από 5 αστέρια με βάση {{count}} κριτικές.",
}

export default translations
