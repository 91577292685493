<script lang="ts">
  import { debounce } from "../Helpers"
  import { selectedLocale, t } from "../lang/i18n"
  import { NumericInputSanitizer } from "../NumericInputSanitizer"

  export let isRequired: boolean = false
  export let onChange: (value: number | null) => void
  export let label: string
  export let value: number | null

  export let info:
    | { position: "prefix" | "suffix"; label: string }
    | undefined = undefined
  export let allowDecimals: boolean

  const isPrefix = info?.position === "prefix"
  const isSuffix = !isPrefix
  const unit = info?.label

  let DECIMAL_SEPARATOR = (1.1).toLocaleString($selectedLocale).substring(1, 2)
  $: DECIMAL_SEPARATOR = (1.1).toLocaleString($selectedLocale).substring(1, 2)

  let numericValueAsString =
    value && isFinite(value)
      ? NumericInputSanitizer(allowDecimals, DECIMAL_SEPARATOR).sanitizeInput(
          `${value}`
        )
      : ""

  const debouncedUpdate = debounce((number: number) => {
    isFinite(number) ? onChange(number) : onChange(null)
  })

  const ariaLabel = label
    ? label
    : unit
      ? $t("numberInputWithUnitLabel", { unit })
      : $t("numberInputDefaultLabel")
</script>

<div class="numeric-input">
  {#if label}
    <label for="numeric-input">{label}</label>
  {/if}

  <div class="input-wrapper">
    <div class="inputs">
      {#if isPrefix && unit}
        <span id="input-unit-label" class="prefix">{unit}</span>
      {/if}
      <input
        id="numeric-input"
        type="number"
        aria-label={ariaLabel}
        aria-describedby="input-unit-label"
        aria-required={isRequired}
        inputmode="decimal"
        autocomplete="off"
        placeholder={allowDecimals ? `0${DECIMAL_SEPARATOR}00` : `0`}
        on:input={function (e) {
          const value = NumericInputSanitizer(
            allowDecimals,
            DECIMAL_SEPARATOR
          ).sanitizeInput(e.currentTarget.value)
          this.value = value

          const num = parseFloat(value.replace(",", "."))
          debouncedUpdate(num)
        }}
        value={numericValueAsString}
      />
      {#if isSuffix && unit}
        <span id="input-unit-label" class="suffix">{unit}</span>
      {/if}
    </div>
  </div>
</div>

<style>
  .numeric-input {
    padding: 0;
    font-family: var(--aiden-input-font-family, "system-ui");
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    font-family: var(--aiden-input-font-family, "system-ui");
    border: var(--aiden-input-border-width, 1px) solid
      var(--aiden-input-border-color, #ebebeb);
    background: var(--aiden-input-background-color, #fff);
    border-radius: var(--aiden-input-border-radius, 0.5rem);
  }
  .input-wrapper:has(input:focus-visible),
  .input-wrapper:has(input:focus) {
    border-color: var(--aiden-input-border-color-focus, #000);
  }

  .inputs {
    width: 100%;
    display: flex;
    gap: 0.4rem;
    align-items: center;
    padding: 0 0.75rem;
    color: var(--aiden-input-font-color);
  }
  .prefix,
  .suffix {
    color: var(--aiden-input-hint-color, #666);
  }
  input {
    width: 100%;
    font-size: 1em;
    border: 0px solid transparent;
    margin: 0 auto;
    box-sizing: border-box;
    -moz-appearance: textfield;
    appearance: textfield;
    background: transparent;
    font-family: var(--aiden-input-font-family, "system-ui");
    font-weight: var(--aiden-input-font-weight, 400);
    color: var(--aiden-input-font-color);
  }
  input:focus,
  input:focus-visible {
    border: 0px solid transparent;
    outline: none;
  }

  .prefix,
  .suffix,
  input {
    padding: 1rem 0;
    line-height: 1;
  }

  label {
    font-weight: bold;
    margin-bottom: 0.25rem;
    display: inline-block;
    font-family: var(--aiden-input-label-font-family, "system-ui");
    font-weight: var(--aiden-input-label-font-weight, 600);
    color: var(--aiden-input-label-color, #000);
  }

  input::placeholder {
    /* color: */
    color: #666;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
