import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Vise mer",
  showLess: "Vis mindre",
  bestMatch: "Beste match",
  view: "Se",
  customAction: "",
  notFound: "404: ikke funnet",
  reset: "Begynne på nytt",
  close: "Lukk",
  noAdviceTitle:
    "For øyeblikket er det ingen tilgjengelige produkter som fullt ut samsvarer med dine preferanser.",
  noAdviceMessage:
    "Du kan endre innstillingene dine, eller prøve igjen senere.",
  prev: "Forrige",
  next: "Neste",
  backToAdvice: "Tilbake til råd",
  dontKnow: "Hopp over",
  email: "Epostadresse",
  send: "Send",
  emailError: "Vennligst skriv inn en gyldig e-post adresse",
  readMore: "Les mer",
  selectAnAnswer: "Velg et svar",
  answerNotListed: "Svaret mitt er ikke oppført",
  whyThisProductForMe: "Hvorfor dette rådet?",
  alternative: "alternativ",
  alternatives: "alternativer",
  seeAlso: "Også se på",
  badMatchIcon: "Dårlig samsvar",
  goodMatchIcon: "God samsvar",
  perfectMatchIcon: "Perfekt samsvar",
  singleAnswerQuestionLegend: "Velg ett alternativ og fortsett.",
  multiAnswerQuestionLegend: "Velg ett eller flere alternativer.",
  multiAnswerQuestionWithNeutralLegend: "Velg ett eller flere alternativer, eller det nederste alternativet hvis du ikke har noen preferanser.",
  manualDrilldownLegend: "Velg ett alternativ. Dersom det er et oppfølgingsspørsmål vil det dukke opp etter valg av svar. Bruk 'Neste'-knappen for å fortsette etter å ha svart på alle spørsmålene.",
  autoDrilldownLegend: "Velg ett alternativ. Dersom det er et oppfølgingsspørsmål vil det dukke opp etter valg av svar. Bruk 'Neste'-knappen for å fortsette etter å ha svart på alle spørsmålene. Står ikke svaret ditt oppført? Velg deretter 'Svaret mitt er ikke oppført'.",
  loading: "Laster",
  progress: "Framgang",
  numberInputDefaultLabel: "Skriv inn et tall.",
  numberInputWithUnitLabel: "Skriv inn et tall i {{unit}}.",
  actionSucceededLabel: "Lykkes",
  actionFailedLabel: "Mislyktes",
  reviewScoreLabel: "Dette produktet er rangert: {{score}} av 5 stjerner basert på {{count}} anmeldelser.",
}

export default translations
