<script lang="ts">
  import type { Page } from "./toPage"
  import type { GetAttribute, OptionsFor, SetAttribute } from "../types"
  import Bounded from "../ui/Bounded.svelte"
  import TopHeader from "../ui/TopHeader.svelte"
  import Button from "../ui/Button.svelte"
  import { navigationContext } from "../stores/navigation"
  import AnswerOptions from "../ui/AnswerOptions.svelte"
  import BottomNavigation from "../ui/BottomNavigation.svelte"
  import EmbeddedVideo from "../EmbeddedVideo.svelte"
  import Image from "../Image.svelte"
  import NumberInput from "../ui/NumberInput.svelte"
  import { answering } from "./answering"
  import Plane from "../ui/Plane.svelte"
  import ArrowRight from "../icons/ArrowRight.svelte"
  import { t } from "../lang/i18n"
  import ManualDrilldown from "./question-inputs/ManualDrilldown.svelte"
  import AutoDrilldown from "./question-inputs/AutoDrilldown.svelte"
  import Recommendation from "./Recommendation.svelte"
  import AdvicePage from "../ui/AdvicePage.svelte"
  import FadeIn from "../FadeIn.svelte"
  import ProductCardTag from "../ui/ProductCard/ProductCardInfo/ProductCardTag.svelte"
  import InfoPage from "./InfoPage.svelte"
  import NoResultsCustomerInfoForm from "./customerInfo/EmptyAdviceCustomerInfoForm.svelte"
  import Loader from "../icons/Loader.svelte"
  import RegularText from "../ui/RegularText.svelte"
  import AdvicePageHeaderActions from "./AdvicePageHeaderActions.svelte"

  export let page: Page
  export let setAttribute: SetAttribute
  export let getAttribute: GetAttribute
  export let optionsFor: OptionsFor

  export let hidePrev: boolean

  const recommendationAriaLabel = (
    productCardTag: string | undefined,
    productName: string
  ) => {
    if (!productCardTag) return productName

    return `${productCardTag}: ${productName}`
  }
</script>

<!-- This triggers a rerender and re-animated the fade-in for new content -->
{#each [page] as scr (scr.screenId)}
  <FadeIn>
    {#if page.type === "error-page"}
      <div class="max-app-width page" style="padding-top: 1rem;">
        <RegularText>
          <p>This app is currently not available</p>
        </RegularText>
      </div>
    {:else if page.type === "start-page"}
      <div class="max-app-width">
        <div
          class="start-page"
          class:legacy-minimal-content={page.legacyShowOnlyButton}
        >
          {#if !page.legacyShowOnlyButton}
            <TopHeader
              title={page.title}
              text={page.text}
              smallText={page.additionalText}
            />
          {/if}

          <Button onClick={$navigationContext.next} variant="primary">
            {page.buttonText}
            {#if $navigationContext.loadingNextScreen}
              <Loader aria-label={$t("loading")}/>
            {/if}
          </Button>
        </div>
      </div>
    {:else if page.type === "question"}
      {@const questionConfig = page.questionConfig}
      <div class="page max-app-width">
        <TopHeader title={page.title} text={page.text}>
          {#if page.media?.type === "image"}
            <Image src={page.media.src} alt="" />
          {:else if page.media?.type === "video"}
            <EmbeddedVideo>
              {@html page.media.src}
            </EmbeddedVideo>
          {/if}
        </TopHeader>

        {#if questionConfig.type === "single"}
          <AnswerOptions
            options={questionConfig.options}
            type="single"
            isSelected={(option) =>
              answering.isSingleSelected(
                getAttribute,
                questionConfig.attributeInstanceId,
                option
              )}
            onSelect={(option) =>
              answering.selectSingleAnswer(
                setAttribute,
                questionConfig.attributeInstanceId,
                option
              )}
          />
        {:else if questionConfig.type === "multi"}
          <AnswerOptions
            options={questionConfig.options}
            neutralAnswerValue={questionConfig.neutralAnswerValue}
            type="multi"
            isSelected={(option) =>
              answering.isMultiSelected(
                getAttribute,
                questionConfig.attributeInstanceId,
                option
              )}
            onSelect={(option) => {
              answering.selectMultiAnswer(
                getAttribute,
                setAttribute,
                questionConfig.attributeInstanceId,
                questionConfig.neutralAnswerValue,
                option
              )
            }}
          />
        {:else if questionConfig.type === "numeric"}
          <Plane>
            <NumberInput
              isRequired={questionConfig.skipAnswerOption === null}
              onChange={(value) => {
                setAttribute(
                  questionConfig.attributeInstanceId,
                  value,
                  "update"
                )
              }}
              label={questionConfig.config.inputLabel}
              allowDecimals={questionConfig.config.allowsDecimals}
              value={answering.getNumericAttribute(getAttribute)(
                questionConfig.attributeInstanceId
              )}
              info={{
                position: questionConfig.config.inputInfoPosition,
                label: questionConfig.config.inputInfoLabel,
              }}
            />
            {#if questionConfig.skipAnswerOption}
              <Button
                className="mt-sm"
                variant="navigation"
                onClick={() =>
                  setAttribute(
                    questionConfig.attributeInstanceId,
                    questionConfig.skipAnswerOption || null,
                    "submit"
                  )}
              >
                <span id="dont-know-button-label">{$t("dontKnow")}</span>
                <ArrowRight aria-labelledby="dont-know-button-label" />
              </Button>
            {/if}
          </Plane>
        {:else if questionConfig.type === "manual-drilldown"}
          <Plane>
            <ManualDrilldown
              onChange={(value) => {
                setAttribute(
                  questionConfig.attributeInstanceId,
                  value,
                  "update"
                )
              }}
              levelLabels={questionConfig.levelLabels}
              options={questionConfig.options}
              value={answering.getSingleAttribute(getAttribute)(
                questionConfig.attributeInstanceId
              )}
            />
          </Plane>
        {:else if questionConfig.type === "auto-drilldown"}
          <Plane>
            <AutoDrilldown
              optionsFor={(field, answers) =>
                optionsFor(field, questionConfig.attributeInstanceId, answers)}
              onChange={(value) => {
                setAttribute(questionConfig.attributeInstanceId, value)
              }}
              levelsConfiguration={questionConfig.levelsConfiguration}
              autoDrilldownAnswerValue={answering.getAutoDrilldownAttribute(
                getAttribute
              )(questionConfig.attributeInstanceId)}
            />
            <Button
              testId="neutral-answer-btn"
              onClick={() => {
                setAttribute(
                  questionConfig.attributeInstanceId,
                  questionConfig.neutralAnswerValue || null,
                  "submit"
                )
              }}
              variant="navigation"
            >
              <span id="answer-not-listed-button-label">{$t("answerNotListed")}</span>
              <ArrowRight ariaLabelledBy="answer-not-listed-button-label" />
            </Button>
          </Plane>
        {/if}
        <BottomNavigation
          onPrev={$navigationContext.prev}
          onNext={$navigationContext.next}
          hideNext={questionConfig.type === "single"}
          {hidePrev}
          nextDisabled={!questionConfig.complete}
        />
      </div>
    {:else if page.type === "info-page"}
      <div class="page max-app-width">
        <InfoPage {page} />
      </div>
    {:else if page.type === "advice-page"}
      <AdvicePage>
        <span slot="top-header">
          <Bounded>
            <TopHeader
              title={page.title}
              text={page.text}
              smallText={page.additionalText}
            />
            {#if page.customerInfoForm.enabled || page.actions.length}
              <AdvicePageHeaderActions {page} />
            {/if}
          </Bounded>
        </span>
        <span slot="bottom-section">
          <Bounded>
            {#each page.recommendations as recommendation}
              <Recommendation
                {recommendation}
                ariaLabel={recommendationAriaLabel(
                  recommendation.state?.type === "best-match"
                    ? recommendation.state.bestMatchText || $t("bestMatch")
                    : $t("alternative"),
                  recommendation.productName
                )}
              >
                {#if recommendation.state}
                  {#if recommendation.state.type === "best-match"}
                    <ProductCardTag
                      topLabel={{
                        kind: "good",
                        label:
                          recommendation.state.bestMatchText || $t("bestMatch"),
                      }}
                    />
                  {/if}
                {/if}
              </Recommendation>
            {/each}
          </Bounded>
        </span>
      </AdvicePage>
    {:else if page.type === "product-check-advice-page"}
      <AdvicePage>
        <span slot="top-header" class="product-check-header">
          <TopHeader text={page.text} markdownTextAllowed="default" />
        </span>
        <span slot="bottom-section">
          <Recommendation
            ariaLabel={recommendationAriaLabel(
              page.firstTag.text || $t("bestMatch"), 
              page.recommendations.recommendation.productName
            )}
            recommendation={page.recommendations.recommendation}
            disabled={!page.isGoodMatch}
          >
            <ProductCardTag
              topLabel={{
                kind: page.firstTag.variant,
                label: page.firstTag.text,
              }}
            />
          </Recommendation>

          {#each page.recommendations.alternatives as recommendation, i}
            <Recommendation
              {recommendation}
              ariaLabel={recommendationAriaLabel(
                $t("alternative"),
                page.recommendations.recommendation.productName
              )}
            >
              {#if i === 0}
                <ProductCardTag
                  topLabel={{
                    kind: page.secondTag.variant,
                    label: page.secondTag.text,
                  }}
                />
              {/if}
            </Recommendation>
          {/each}
        </span>
      </AdvicePage>
    {:else if page.type === "empty-advice-page"}
      <AdvicePage>
        <span slot="top-header">
          <Bounded>
            <TopHeader
              title={page.title || $t("noAdviceTitle")}
              text={page.text || $t("noAdviceMessage")}
              markdownTextAllowed="default"
            />
            {#if page.customerInfoForm.enabled}
              <NoResultsCustomerInfoForm formConfig={page.customerInfoForm} />
            {/if}
          </Bounded>
        </span>
      </AdvicePage>
    {/if}
  </FadeIn>
{/each}

<style>
  :global(.max-app-width) {
    --max-app-width: 108rem;

    max-width: var(--max-app-width);
    margin: 0 auto;
  }
  .start-page {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content;
    width: 100%;
    place-content: var(--aiden-start-page-justify-content, start);
    place-items: var(--aiden-start-page-justify-content, start);
    padding-left: var(--frame-padding, 1rem);
    padding-right: var(--frame-padding, 1rem);
    box-sizing: border-box;

    text-align: var(--aiden-start-page-text-align, left) !important;
    background-color: var(--aiden-app-background-color, #fff);

    --aiden-title-font-size: var(--aiden-start-page-title-font-size, 2rem);
    --aiden-title-color: var(--aiden-start-page-title-color, #000);
    --aiden-regular-text-font-size: var(
      --aiden-start-page-regular-text-font-size,
      1rem
    );

    --aiden-regular-text-color: var(
      --aiden-start-page-regular-text-color,
      #000
    );
    --aiden-link-color: var(--aiden-start-page-link-color, #000);
    /* --aiden-small-text-font-size: var(
      --aiden-start-page-small-text-font-size,
      0.75rem
    );

    --aiden-small-text-color: var(--aiden-start-page-small-text-color, #000); */
  }
  .start-page :global(.text-content) {
    justify-self: var(--aiden-start-page-justify-content, start);
  }
  .start-page.legacy-minimal-content {
    justify-content: start;
    place-items: start;
  }

  .page {
    padding-left: var(--frame-padding, 1rem);
    padding-right: var(--frame-padding, 1rem);
    box-sizing: border-box;
  }
  .start-page,
  .page {
    padding-bottom: var(--frame-padding, 1rem);
  }

  .product-check-header {
    --aiden-regular-text-font-size: 1.25rem;
    /* --aiden-small-text-font-size: 1rem; */
  }
</style>
