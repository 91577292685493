<div>
  <svg
    {...$$restProps}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="size-6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="m8.25 4.5 7.5 7.5-7.5 7.5"
    />
  </svg>
</div>

<style>
  div {
    transition: 150ms color ease-out;
    color: var(--aiden-chevron-color);
    height: 24px;
  }
  /*
  div svg {
    box-sizing: border-box;
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    margin-top: -2px;
    margin-left: -2px;
    color: transparent;
    top: 1px;
    left: 1px;
    border: 2px solid transparent;
    z-index: 5;
    transition: all 250ms ease-out;
    -webkit-transition: all 250ms ease-out;
  }
*/
</style>
