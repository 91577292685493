<script lang="ts">
  import { appAnalyticsStore } from "../analytics/appAnalytics"
  import ArrowRight from "../icons/ArrowRight.svelte"
  import { t } from "../lang/i18n"
  import { advisorInfoStore } from "../stores"
  import ActionButton from "../ui/ProductCard/ActionButton.svelte"
  import ProductCard from "../ui/ProductCard/ProductCard.svelte"
  import ProductCardFeatures from "../ui/ProductCard/ProductCardFeatures.svelte"
  import ProductCardFields from "../ui/ProductCard/ProductCardFields.svelte"
  import ProductCardEEI from "../ui/ProductCard/ProductCardEEI.svelte"
  import ProductCardImage from "../ui/ProductCard/ProductCardImage.svelte"
  import ProductCardName from "../ui/ProductCard/ProductCardInfo/ProductCardName.svelte"
  import ProductCardPrice from "../ui/ProductCard/ProductCardInfo/ProductCardPrice.svelte"
  import ProductCardVariants from "../ui/ProductCard/ProductCardVariants.svelte"
  import ViewProductButton from "../ui/ProductCard/ViewProductButton.svelte"
  import Cart from "../icons/Cart.svelte"
  import {
    productClickHandler as productClick,
    type ProductInContextClick,
  } from "./productClickHandler"
  import type { Recommendation } from "./toPage"
  import ProductCardReview from "../ui/ProductCard/ProductCardReview.svelte"

  export let recommendation: Recommendation
  export let disabled: boolean = false
  export let ariaLabel: string

  let productClickHandler: ReturnType<typeof productClick> | undefined
  $: productClickHandler = productClick(
    $appAnalyticsStore,
    $advisorInfoStore.advisorId
  )

  let recommendationClick: ProductInContextClick = () => {}
  $: recommendationClick = (event, location, clickType) => {
    productClickHandler?.onClick(
      event,
      recommendation,
      recommendation.isProductCheckProduct,
      location,
      clickType
    )
  }
</script>

<ProductCard {disabled} {ariaLabel}>
  <span slot="product-card-tags" class="product-card-tags"><slot></slot></span>
  <span slot="product-card-image">
    <ProductCardImage
      productName={recommendation.productName}
      imageUrl={recommendation.imageUrl}
      productUrl={recommendation.productUrl}
      onClick={recommendationClick}
      linkTarget="_parent"
    />
  </span>
  <span slot="product-card-body">
    <div class="mb-lg">
      <!-- Visually their is a difference between 'name' and 'price' if there is a review score so a different margin is used -->
      <div class={recommendation.reviewScore !== undefined ? "mb-md" : "mb-sm"}>
        <div>
          <ProductCardName
            productUrl={recommendation.productUrl}
            productName={recommendation.productName}
            onClick={recommendationClick}
            linkTarget="_parent"
          />
        </div>

        {#if recommendation.reviewScore !== undefined}
          <div style="margin-top: 4px">
            <ProductCardReview
              score={recommendation.reviewScore}
              count={recommendation.reviewCount}
            />
          </div>
        {/if}
      </div>

      {#if recommendation.price && recommendation.display.price}
        <ProductCardPrice
          prefix={recommendation.pricePrefix}
          suffix={recommendation.priceSuffix}
          price={recommendation.price}
          priceAnnotationDuringSale={recommendation.salePrice
            ?.previousPriceAnnotation || ""}
          salePrice={recommendation.salePrice?.price}
        />
      {/if}
    </div>

    {#if recommendation.energyLabelField}
      <div class="mb-md">
        <ProductCardEEI eeiProductField={recommendation.energyLabelField} />
      </div>
    {/if}
    {#if recommendation.additionalFields.length}
      <div class="mb-lg">
        <ProductCardFields productFields={recommendation.additionalFields} />
      </div>
    {/if}
  </span>
  <span slot="product-card-buttons">
    {#if !disabled}
      {#if recommendation.display.productCta}
        {#if recommendation.display.ctaType === "AddToCart"}
          <ActionButton
            actionIdentifier="aiden-add-to-cart"
            testId="action-button"
            variant="primary"
            isWide
            icon={Cart}
            context={{
              recommendation: recommendation,
              recommendationContext: recommendation.recommendationContext,
              location: "ADVICE_PAGE",
              triggerLocation: "PRODUCT_CTA",
            }}
            analyticsCallback={() => {
              productClickHandler?.measureProductClick(
                recommendation,
                "LINK",
                "PRODUCT_CTA"
              )
            }}
          >
            <span>{recommendation.buttonCtaText}</span>
          </ActionButton>
        {/if}
        {#if recommendation.display.ctaType === "ProductLink" && recommendation.productUrl}
          <ViewProductButton
            href={recommendation.productUrl}
            onClick={recommendationClick}
            testId="view-product-cta"
          >
            <span id="view-product-button-label">{recommendation.buttonCtaText || $t("view")}</span>
            <ArrowRight aria-labelledby="view-product-button-label" />
          </ViewProductButton>
        {/if}
      {/if}
    {/if}
  </span>
  <div slot="product-card-bottom">
    {#if recommendation.display.traceability && recommendation.traceability.length}
      <ProductCardFeatures horizontal features={recommendation.traceability} />
    {/if}
    {#if recommendation.variants}
      <ProductCardVariants
        onClick={(event, product, location, clickType) => {
          productClickHandler?.onClick(
            event,
            product,
            false,
            location,
            clickType
          )
        }}
        variants={recommendation.variants}
      />
    {/if}
  </div>
</ProductCard>

<style>
  .mb-sm {
    margin-bottom: 0;
  }
  .mb-md {
    margin-bottom: 0.55rem;
  }
  .mb-lg {
    margin-bottom: 0.75rem;
  }

  @container (min-width: 500px) {
    .mb-sm {
      margin-bottom: 0.25rem;
    }

    .mb-lg {
      margin-bottom: 1rem;
    }
  }
</style>
