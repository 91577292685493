import React, { useEffect } from "react"
import { Question as QuestionStruct, QuestionType }from "~/store/questions/types"
import { v4 as uuid } from "uuid"
import useNavigation from "~/hooks/useNavigation"
import { useAuth } from "~/context/auth"
import { UUID }from "~/store/types"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState }from "~/store"
import { getQuestions }from "~/store/questions/selectors"
import { getAnswers }from "~/store/answers/selectors"
import { getRules }from "~/store/rules/selectors"
import { changeRuleTarget }from "~/actions/rules"
import { useTranslation } from "react-i18next"
import { useQandASidebarContext } from "../QandASidebarContext"
import { getScreens }from "~/store/screens/selectors"
import ElmConversationsCanvas from "~/elm-tsx-wrappers/ElmConversationsCanvas"
import Conversation, { MatchingStat } from "~/lib/Conversation"
import useNotifications from "~/hooks/useNotifications"
import useQuestions from "~/hooks/useQuestions"
import { addAfter, Flow }from "~/actions/flow"

const Canvas: React.FC<{
  advisorId: UUID
  selectedQuestionId?: UUID
  selectedScreenId?: UUID
  questionIds: UUID[]
  addQuestion: (
    question?: Partial<QuestionStruct>,
    previousQuestionId?: string
  ) => void
  removeQuestion: (questionId: UUID) => void
}> = ({
  questionIds,
  addQuestion,
  removeQuestion,
  advisorId,
  selectedQuestionId,
  selectedScreenId,
}) => {
  const { changeCollapse } = useQandASidebarContext()

  const dispatch = useDispatch()
  const questions = useSelector((state: ApplicationState) =>
    getQuestions(state, questionIds).map((q) => ({
      ...q,
      answers: getAnswers(state, q).filter((a) => a.title !== ""),
      rules: getRules(state, q.rules),
    }))
  )

  const { t } = useTranslation()
  const { token, organisationId } = useAuth()
  const { notify } = useNotifications()

  const { toAdvisorFlow, toRulesOfQuestion, toRuleOfQuestion } = useNavigation()
  const screens = useSelector((state: ApplicationState) =>
    getScreens(state, advisorId)
  )

  const isMatchingStat = (obj: any): obj is MatchingStat =>
    typeof obj === "object" && "hasMatching" in obj && "count" in obj

  const { questionsWithAnswers } = useQuestions(advisorId)
  const productLabel = useSelector(
    (state: ApplicationState) => state.advisors[advisorId]?.productLabel
  )

  const hardDeleteQuestion = React.useCallback(
    (questionId: UUID) => {
      removeQuestion(questionId)
      toAdvisorFlow(advisorId!)
    },
    [removeQuestion, toAdvisorFlow, advisorId]
  )

  const deleteQuestion = React.useCallback(
    (questionId: UUID, confirmedDeletion: boolean) => {
      if (confirmedDeletion) {
        return hardDeleteQuestion(questionId)
      }

      const question = questions.find((q) => q.id === questionId)
      const answers =
        questionsWithAnswers.find((q) => q.id === question?.id)?.answers || []

      const statsFunc =
        question?.type === QuestionType.Numeric
          ? () =>
              Conversation(advisorId)(
                token!!,
                organisationId
              ).numericQuestionMatchingStats(questionId)
          : () =>
              Conversation(advisorId)(
                token!!,
                organisationId
              ).questionMatchingStats(questionId)

      const checkMatching = async () => {
        try {
          const stats = await statsFunc()

          if (stats.hasMatching) {
            if (isMatchingStat(stats)) {
              return {
                matchingType: "NUMERIC_MATCHING",
                questionId: questionId,
                questionLabel: question?.label,
                productLabel: productLabel,
                count: stats.count,
              }
            } else {
              return {
                matchingType: "MATCHING",
                questionId: questionId,
                questionLabel: question?.label,
                productLabel: productLabel,
                matches: answers
                  .filter((a) => !a.isNeutralAnswer)
                  .map((a) => {
                    return {
                      count: stats.stats[a.id].count,
                      answerLabel: a.title,
                    }
                  }),
              }
            }
          } else {
            hardDeleteQuestion(questionId)
          }
        } catch (e) {
          notify({ type: "error", text: t("errors.error") })
        }
      }
      return checkMatching()
    },
    [
      advisorId,
      hardDeleteQuestion,
      notify,
      productLabel,
      questions,
      questionsWithAnswers,
      t,
      token,
      organisationId,
    ]
  )

  const openFlowTab = React.useCallback(
    (questionId, ruleId) => {
      ruleId
        ? toRuleOfQuestion(advisorId!, questionId, ruleId)
        : toRulesOfQuestion(advisorId!, questionId)
    },
    [toRuleOfQuestion, advisorId, toRulesOfQuestion]
  )

  useEffect(() => {
    if (selectedQuestionId) {
      const tm = setTimeout(() => {
        document.getElementById(selectedQuestionId)?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        })
      }, 0)
      return () => clearTimeout(tm)
    }
  }, [selectedQuestionId])

  return (
    <ElmConversationsCanvas
      advisorId={advisorId}
      deleteQuestion={deleteQuestion}
      openFlowTab={openFlowTab}
      selectedNodeId={
        selectedScreenId
          ? selectedScreenId === screens?.intro.id
            ? "START"
            : selectedScreenId === screens?.advice.id
            ? "ADVICE"
            : selectedScreenId
          : selectedQuestionId
      }
    />
  )
}

export default Canvas
