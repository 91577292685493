<script lang="ts">
  import ActionButton from "../ui/ProductCard/ActionButton.svelte"
  import ShoppingCart from "../icons/ShoppingCart.svelte"
  import AdviceCustomerInfoForm from "./customerInfo/AdviceCustomerInfoForm.svelte"
  import type { Page } from "./toPage"

  export let page: Extract<Page, { type: "advice-page" }>

  $: showCustomerInfoForm = false

  const setshowCustomerInfoForm = (show: boolean) => {
    showCustomerInfoForm = show
  }
</script>

<div class="advice-page-header-actions" class:is-column={showCustomerInfoForm}>
  {#each page.actions as action}
    {#if action.type === "AddToCart"}
      <ActionButton
        variant={action.buttonType}
        testId="header-action-button"
        icon={ShoppingCart}
        context={{
          recommendations: page.recommendations,
          location: "ADVICE_PAGE",
          triggerLocation: "PAGE_HEADER",
        }}
        actionIdentifier="aiden-add-to-cart"
      >
        {action.label}
      </ActionButton>
    {/if}
  {/each}
  {#if page.customerInfoForm.enabled}
    <AdviceCustomerInfoForm
      {setshowCustomerInfoForm}
      {showCustomerInfoForm}
      formConfig={page.customerInfoForm}
      recommendations={page.recommendations}
    />
  {/if}
</div>

<style>
  .advice-page-header-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > button {
      margin-right: 1rem;
    }
    
    &.is-column {
      flex-direction: column;
      align-items: flex-start;

      & > * {
        align-self: flex-start;
      }
    }
  }
</style>
