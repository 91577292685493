import { setFlag } from "~/actions/featureFlags"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "~/store"

export enum FeatureFlag {
  customerInfoCollectionEnabled = "customerInfoCollectionEnabled",
  showPoweredByAiden = "showPoweredByAiden",
  newCreateAppFlowEnabled = "newCreateAppFlowEnabled",
  packageAdviceEnabled = "packageAdviceEnabled",
  pdpIntegrationEnabled = "pdpIntegrationEnabled",
  customActionEnabled = "customActionEnabled",
  globalStylingEnabled = "globalStylingEnabled",
  // Analytics integrations
  // allIntegrationsEnabled = "integrationsEnabled",
  bloomreachEnabled = "bloomreachEnabled",
  googleAnalyticsEnabled = "googleAnalyticsEnabled",
  spotlerActivateLegacyEnabled = "spotlerActivateLegacyEnabled",
  spotlerActivateEnabled = "spotlerActivateEnabled",
  customIntegrationEnabled = "customIntegrationEnabled",
  aiQuickstartEnabled = "aiQuickstartEnabled",
}

export type FeatureFlags = Record<FeatureFlag, boolean>

export const useFeatureFlags = () => {
  const dispatch = useDispatch()

  const featureFlags: FeatureFlags = useSelector(
    (store: ApplicationState) => store.featureFlags
  )

  const setFlagDispatch = React.useCallback(
    (flag: FeatureFlag, bool: boolean) => {
      dispatch(setFlag(flag, bool))
    },
    [dispatch]
  )

  return {
    featureFlags: featureFlags,
    setFlag: setFlagDispatch,
  }
}
